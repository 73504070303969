/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var $input = $('header input.orig'),
            $formContainer = $('header .wpdreams_asl_container'),
            visibleClass = 'mm_search-toggle',
            hideSearchTimerId = 0;
        
        $formContainer.on('hover focus', function(){
          clearTimeout(hideSearchTimerId);
          $input.addClass(visibleClass);
        });
        
        $input.on('blur', function() { 
            if($(this).val().length === 0){
              hideSearchTimerId = window.setTimeout(function() {
                $input.removeClass(visibleClass);
              }, 2000);
            }
        });

        $formContainer.on('mouseleave', function() { 
            if($input.val().length > 0 || $input.is(':focus')){
              return;
            }
            
            hideSearchTimerId = window.setTimeout(function() {
              $input.removeClass(visibleClass);
            }, 2000);
        });

        $('.navbar-toggler').on('click', function(){
          $('nav.navbar .collapse').toggleClass('show');
        });

        $(document).on('click', '.scroll-to[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top
            }, 500);
        }).on('click', '[data-btn-signin]', function(evt){
            var target;

            if (this.hash) {
                target = $(this.hash);

                if (target.length) {
                    evt.preventDefault();

                    $('html,body').animate({
                        scrollTop: target.first().offset().top - 100
                    }, 500);
                }
            }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        $("#owl-carousel-main-slider").owlCarousel({
            slideSpeed : 300,
            paginationSpeed : 400,
            singleItem: true,
            pagination: true,
            rewindSpeed: 500,
            items:1,
            autoHeight : true,
            navigation:false,
            autoplay: true,
            autoplayTimeout: 8000,
            rewind: true,
            loop: true
        });
        
        $('.owl-carousel-blog').owlCarousel({
          margin: 10,
          loop: true,
          navigation: true,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 1
            },
            1000: {
              items: 2
            }
          }
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_post': {
      init: function() {
        $("#unlock-form").on('wpcf7:mailsent', function(event){
            window.setTimeout(function() {
                $('html,body').animate({
                    scrollTop: 0
                }, 300);
            }, 4700);

            window.setTimeout(function() {
                window.location.reload();
            }, 5000);
        });
      }
    },
    'biblioteka': {
      init: function() {
        $(".wpcf7").on('wpcf7:mailsent', function(event){
          window.setTimeout(function() {
            window.location.hash = '';
            document.body.scrollTop = 0;
            window.location.reload();

          }, 3600);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
