(function($){
    "use strict";

    $(document).on('click', '[data-btn-goto]', function(evt){
        var target = this.getAttribute('data-btn-goto');
        var $target;

        if (!target.length) {
            target = this.hash ? this.hash : false;
        }

        if (target) {
            $target = $(target);
        }

        if ($target.length) {
            evt.preventDefault();

            $('html,body').animate({
                scrollTop: $target.first().offset().top - 100
            }, 600);
        }
    });

    // timery
    $('[data-countdown]').each(function(){
        var countdownTo = new Date(this.getAttribute('data-countdown')),
            now = new Date(),
            $days = $('[data-countdown-counter="days"]', this),
            $hours = $('[data-countdown-counter="hours"]', this),
            $minutes = $('[data-countdown-counter="minutes"]', this),
            $seconds = $('[data-countdown-counter="seconds"]', this);
        var interval;

        if (countdownTo <= now) {
            $days.text('00');
            $hours.text('00');
            $minutes.text('00');
            seconds.text('00');

            return;
        }

        interval = window.setInterval(function() {
            var now = new Date().getTime();
            var distance = countdownTo - now;

            var days = "00"+(Math.floor(distance / (1000 * 60 * 60 * 24)));
            var hours = "00"+(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            var minutes = "00"+(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            var seconds = "00"+(Math.floor((distance % (1000 * 60)) / 1000));

            $days.text(days.slice(-2));
            $hours.text(hours.slice(-2));
            $minutes.text(minutes.slice(-2));
            $seconds.text(seconds.slice(-2));

            if (distance <= 0) {
                $days.text('00');
                $hours.text('00');
                $minutes.text('00');
                $seconds.text('00');

                window.clearInterval(interval);
            }
        }, 1000);
    });
})(jQuery);
